<template>
    <div id="logins">

        <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,800" rel="stylesheet" />

        <div class="fs-container">
            <div class="fs-container__bg"></div>
            <div class="content">
                <div class="content-login">
                    <div class="content__pane init2">
                        <img class="logo" src="@/assets/emt-logo-small.png" style="max-width: 60px" />

                        <form name="login" id="login-form" novalidate="" method="POST" v-on:submit.prevent
                            style="max-width: 286px;">
                            <div class="flex-container">
                                <input type="hidden" id="t_login" name="t_login" value="NO_QRCODE" />

                                <div id="classic-login">
                                    <div class="group filled">
                                        <input v-model="input.username" id="user" class="user" required="" name="user"
                                            type="text" value="" />

                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label>
                                            <img src="@/assets/loginstart/img/user.png" />
                                            <span class="label__text label__text--hidden">Username</span>
                                        </label>
                                    </div>

                                    <div class="group filled" style="margin-bottom: 0px">
                                        <input ref="currPass" v-model="input.password" id="password" class="password"
                                            required="" name="password" type="password" value="" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <img @click="showPassword" class="showpwd" src="@/assets/icons8-eye-24.png" />

                                        <label>
                                            <span class="label__text label__text--hidden">Password</span>
                                            <img src="@/assets/loginstart/img/pwd.png" />
                                        </label>

                                        <a id="forgotPass" @click="recover">
                                            Forgot password?
                                        </a>


                                    </div>
                                </div>

                                <div id="qr-code-wrapper">
                                    <button type="button" id="qr-code-btn">
                                        <span class="tooltiptext">Login with QR-Code</span>

                                        <img src="@/assets/loginstart/img/qr-btn-alpha.png" alt="submit" />
                                    </button>
                                </div>

                                <!-- added code -->

                                <div class="container" id="qr-login" style="display: none">
                                    <div>
                                        <a class="button" id="resetButton">Back</a>
                                    </div>

                                    <div>
                                        <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
                                    </div>

                                    <div id="sourceSelectPanel" style="display: none">
                                        <label for="sourceSelect">Change video source:</label>
                                        <select id="sourceSelect" style="max-width: 400px"></select>
                                    </div>

                                    <div style="text-align: left; display: none">
                                        <label>Result:</label>
                                        <pre><code id="result"></code></pre>
                                    </div>
                                </div>

                                <!-- end added code -->
                            </div>

                            <div id="lang-select" class="group select">
                                <div class="lang__field">
                                    <span class="lang__selected"><img src="@/assets/loginstart/img/uk.png"
                                            class="flag-img" /></span>
                                    <label class="select__label">
                                        <img src="@/assets/loginstart/img/language.png" />
                                    </label>
                                    <div class="lang__options">
                                        <div data-value="EN" class="lang__option">
                                            <img src="@/assets/loginstart/img/uk.png" class="flag-img" />
                                        </div>
                                        <div data-value="IT" class="lang__option">
                                            <img src="@/assets/loginstart/img/italy.png" class="flag-img" />
                                        </div>
                                    </div>
                                </div>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>

                            <div id="otp-select" class="group select">
                                <div class="otp__field">
                                    <span class="otp__selected">
                                        <div class="otp-text">Email</div>
                                        <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                    </span>
                                    <label class="select__label">
                                        <div class="text-label">OTP</div>
                                        <img src="@/assets/loginstart/img/token.png" />
                                    </label>
                                    <div class="otp__options">
                                        <div data-value="MAIL" class="otp__option">
                                            <div class="otp-text">Email</div>
                                            <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                        </div>
                                        <div data-value="SMS" class="otp__option">
                                            <div class="otp-text">Sms</div>
                                            <img src="@/assets/loginstart/img/sms.png" class="otp-img" />
                                        </div>

                                        <div data-value="Auth" class="otp__option">
                                            <div class="otp-text">Auth</div>
                                            <img src="@/assets/loginstart/img/auth.png" class="otp-img" />
                                        </div>
                                    </div>
                                </div>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>

                            <button class="
                                          button button--block button--outline button--primary
                                          modal-trigger
                                        " id="login-button" value="login" v-on:click="login()">
                                <div class="button--text">Login</div>
                                <div class="spinner hidden">
                                    <div class="holder">
                                        <div class="preloader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </button>

                            <rise-loader v-if="showSpinner" :size="'20px'" :color="'#fa0025'" />
                            <!-- <div class="info info--error info--hidden">
                Devi inserire sia il nome utente sia la password
              </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="css" scoped>
@import "./../assets/loginstart/style.css";
</style>

<script>
import $ from 'jquery';
import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
    components: {
        /* PulseLoader, */
        RiseLoader,
    },

    updated() { },

    mountedOLD: function () {
        console.log(this.$route.path);
        if (this.$route.path == "/") {
            // console.log( this.$parent );
            //  window.App.ok = 1 ;
            //   App.hideToolbars = true ;
            // Vue.set(this.$root , 'hideToolbars', true)
        } else {
            // window.App.ok = 0 ;
            //  App.hideToolbars = false ;
            // this.$root.hideToolbars = false ;
            // Vue.set(this.$root , 'hideToolbars', false)
        }
    },
    mounted: function () {
        var loggedIn = this.$cookies.get('token');

        if (loggedIn) {
            router.push({
                path: "/dash"
            });
        }
        console.log("Ciao: " + this.userLogged);

        //        this.$cookie.get('jwt_token')
        //console.log ( this.$cookie.getAll() );
        //$cookie.set('cookie_name', 'cookie_value');
        //window.$cookie.set('test', 'Hello world!', 1);
        //  console.log (window.$cookies.getCookie()  );
        // const username = this.$cookies.get("username");
        //console.log(username);
        //        this.$cookie.getAll()
    },
    name: "Login",
    data() {
        return {
            dialogRegister: true,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },
        };
    },
    methods: {

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },


        showPassword() {

            var currPass = this.$refs.currPass;
            if (currPass.type === "password") {
                currPass.type = "text"
                currPass.btnText = "Hide Password"
            } else {
                currPass.type = "password"
                currPass.btnText = "Show Password"
            }
        },


        getUrlDomain2: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        recover: function () {
            // this.showSpinner = true;

            var site_domain = "https://app.emtool.eu/";

            var spinner = $("#bspinner");
            var loginButton = $("#login-button");

            var API_URL = this.getUrlDomain2("resetPwdEmtApp");

            var that = this;
            this.$swal
                .fire({
                    title: "Password recovery",
                    html: "Type your username",
                    /*  icon: "warning", */
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertmsg.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },

                    input: "text",

                    inputAttributes: {
                        autocapitalize: "off",
                    },

                    preConfirm: (username) => {
                        // console.log(username);

                        // show spinner && disable button
                        spinner.removeClass("vhidden");
                        that.showSpinner = true;

                        loginButton.prop("disabled", true);

                        var body = {
                            user: username,
                            method: "SEND_OLD_PASSWORD",
                            "site_domain": site_domain,
                            product: 'Emt'
                        };

                        console.log(body);
                        // call the service
                        $.ajax({
                            type: "POST",
                            url: API_URL,
                            data: body,
                            success: function (resultData) {

                                console.log("RESULTDATA: ", resultData);

                                // hide spinner && enable button
                                spinner.addClass("vhidden");
                                that.showSpinner = false;

                                loginButton.prop("disabled", false);

                                if (resultData.Result == "OK") {
                                    var message;

                                    message = "Check your email";

                                    //open ok modal

                                    that.$swal.fire({
                                        icon: "success",
                                        title: message,
                                        showConfirmButton: true,
                                    });
                                } else {
                                    var errorMessage;

                                    switch (resultData.Result) {
                                        case "USER_NOT_FOUND":
                                            errorMessage = "User not found";
                                            break;
                                        default:
                                            errorMessage = "User not found ";
                                    }

                                    that.$swal.fire({
                                        title: "Errors",
                                        text: errorMessage,
                                        icon: "error",
                                        confirmButtonText: "Ok",
                                    });

                                    that.showSpinner = false;
                                }



                            },
                            error: function () {
                                // hide spinner && enable login button
                                spinner.addClass("vhidden");
                                loginButton.prop("disabled", false);
                                that.showSpinner = false;
                                //  errorHandler(event);
                            },
                        });
                    },

                })

        },


        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },



        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },

        loginAction: async function () {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            const url = this.getUrlLoginApi();

            this.success = false;
            this.error = null;

            let formData = new FormData();

            formData.append("user", this.input.username);
            formData.append("password", this.input.password);
            formData.append("productid", "Alertmsg");

            var login = this.input.username;
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then(async (result) => {
                        var respo = result.data;
                        this.success = true;
                        console.log("RESPO: ", respo);
                        console.log("LOGIN: ", login);

                        if (respo.Result == "OK") {

                            if (respo.product_enable == 1) // prodotto abilitato
                            {


                                var is_ok = 1;

                                if (respo.is_trial == "S") // abilitata la versione trial
                                {

                                    var start_date = new Date(respo.start_trial + " 00:00:00");
                                    var end_date = new Date(respo.end_trial + " 23:59:59");
                                    var cur_date = new Date();

                                    console.log("START TRIAL: ", start_date);
                                    console.log("END TRIAL: ", end_date);
                                    console.log("CUR DATE: ", cur_date);


                                    if (cur_date < start_date) {


                                        is_ok = 0;

                                    }

                                    if (is_ok == 1) {

                                        if (cur_date > end_date) {


                                            is_ok = 0;

                                        }

                                    }



                                }


                                console.log("IS OK: ", is_ok);


                                if (is_ok == 1) {

                                    setTimeout(() => {

                                        window.$cookies.set("a", respo.a, "9y");
                                        window.$cookies.set("b", respo.b, "9y");
                                        window.$cookies.set("token", respo.token, "9y");
                                        window.$cookies.set("system", respo.system, "9y");
                                        window.$cookies.set("token_system", respo.token_system, "9y");
                                        window.$cookies.set("username", respo.username, "9y");
                                        window.$cookies.set("user_id", respo.user_id, "9y");
                                        window.$cookies.set("namesurname", respo.namesurname, "9y");
                                        window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");
                                        window.$cookies.set("is_agila", respo.is_agila, "9y");
                                        window.$cookies.set("models", respo.models, "9y");
                                        window.$cookies.set("product_permissions", respo.product_permissions, "9y");
                                        window.$cookies.set("is_trial", respo.is_trial, "9y");
                                        window.$cookies.set("start_trial", respo.start_trial, "9y");
                                        window.$cookies.set("end_trial", respo.end_trial, "9y");
                                        window.$cookies.set("is_msg_user", 0, "9y");
                                        window.$cookies.set("reload_messages", 0, "9y");

                                    }, 1);


                                    try { // verifico se è la prima volta che accede gli chiedo cambio pwd

                                        // const url2 = "http://emtool.local/api/pswUserIsChanged";

                                        const url2 = this.getUrlDomainApi("pswUserIsChanged");

                                        let formData2 = new FormData();
                                        formData2.append("username", this.input.username);

                                        await this.axios
                                            .post(url2, formData2, {
                                                "content-type": "application/json"
                                            })
                                            .then((result2) => {

                                                this.showSpinner = false;

                                                console.log("RESULT2: ", result2);

                                                setTimeout(() => {

                                                    window.$cookies.set("is_pwd_changed_once", result2.data.IsChanged, "9y");

                                                }, 1);

                                                if (result2.data.IsChanged == 0) {


                                                    setTimeout(() => {
                                                        router.push({
                                                            path: "/changepasswordinitial"
                                                        });

                                                    }, 300);




                                                }

                                                if (result2.data.IsChanged == 1) {

                                                    setTimeout(() => {

                                                        var uname = login.replace("@", "_");
                                                        const args = [uname, true, ['bar', 5], {
                                                            foo: 'baz'
                                                        }];
                                                        try {
                                                            console.log("entering user");
                                                            window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                                        } catch (error) {
                                                            // alert(error);
                                                        }

                                                        this.$root.$children[0].curDipTop = respo.namesurname;

                                                        //update menu after login
                                                        this.$root.$children[0].changeMenu();
                                                        //Load companies array
                                                        this.$root.$children[0].syncMonth();
                                                        this.$root.$children[0].syncGroups();

                                                        this.$root.$children[0].refreshMenu();

                                                        this.$root.$children[0].registerLogin();


                                                        this.$cookies.set("last_menu", "/messages");

                                                        setTimeout(() => {
                                                            router.push({
                                                                path: "/dash"
                                                            });

                                                        }, 300);



                                                    }, 1);


                                                }



                                            });




                                    }
                                    catch (err2) {
                                        this.success = false;
                                        console.log(err2.message);
                                        this.$swal({
                                            icon: "error",
                                            text: "Errore durante verifica password"
                                        });

                                        this.showSpinner = false;
                                    }




                                }
                                else // versione trial terminata
                                {

                                    this.$swal({
                                        icon: "error",
                                        text: "Periodo trial terminato",
                                        showConfirmButton: false,
                                        timer: 3000
                                    });

                                    this.showSpinner = false;


                                }








                            }
                            else {


                                this.$swal({
                                    icon: "error",
                                    text: "Prodotto non abilitato",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                this.showSpinner = false;



                            }





                        }
                        else {

                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertmsg.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;




                        }









                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                this.$swal({
                    icon: "error",
                    text: "Credenziali sbagliate",
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertmsg.png' />",


                });

                this.showSpinner = false;
            }
        },

        login() {
            this.showSpinner = true;
            this.getCookie();
            this.loginAction();

        },
    },
};
</script>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}

img.showpwd {
    position: absolute;
    /* right: 0px; */
    top: 14px;
    right: 0px;
    max-width: 22px;
    cursor: pointer;

}
</style>
